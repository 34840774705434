import { http } from '../http';

const getEntityPdf = async (id, data, cancelToken) => {
  const response = await http.post(`/api/wpio/v1/pdf/podmiot_lub_osoba/${id}/generuj_podmiot`, data, { cancelToken });
  return response.data.pdf_id;
};

const getPersonPdf = async (id, data, cancelToken) => {
  const response = await http.post(`/api/wpio/v1/pdf/podmiot_lub_osoba/${id}/generuj_osoba`, data, { cancelToken });
  return response.data.pdf_id;
};

const getPdfStatus = async (id, cancelToken) => {
  const response = await http.get(`/api/wpio/v1/pdf/task/${id}/status`, { cancelToken });
  return response.data;
};

const getPdfFile = async (id, cancelToken) => {
  const response = await http.get(`/api/wpio/v1/pdf/task/${id}/pobierz`, {
    responseType: 'arraybuffer',
    cancelToken,
  });
  return response.data;
};

const cancelGeneratePdf = async id => {
  await http.post(`/api/wpio/v1/pdf/task/${id}/kill`);
};

export default {
  getEntityPdf,
  getPersonPdf,
  getPdfStatus,
  getPdfFile,
  cancelGeneratePdf,
};
